<template>
<div class="bg-brilliant">
  <div style="min-height:800px;">
    <v-col cols="12" sm="7" md="5" lg="4" 
          class="mx-auto text-center" style="padding-top:10px;">
      <router-link to="/">
        <v-img src="../assets/img/monnaie_pirate_logo.png" class="d-inline-flex" width="100"/>
      </router-link>
      <h1 class="font-main pb-1 pt-0 mb-5 pb-5 yellow--text text--darken-2" style="font-size:45px;margin-top:-50px;">
        La monnaie pirate
      </h1>
      <Login></Login>
    </v-col>
  </div>
  <v-col cols="12" class="pa-0 mt-5">
    <Footer/>
  </v-col>
</div>
</template>

<script>

//import router from '../router/router'
import Footer from '@/components/main-layout/Footer.vue'
import Login from '@/components/main-layout/Login.vue'

export default {
  name: 'login',
  components: {
    Login, Footer
  },
  data: () => ({
    email: '',
    password: '',
    resColor: '',
    resMsg: '',
    showpwd: false
  }),
  async mounted(){
    this.$store.dispatch('app/incStat', '/login')
  },
  methods: {
    async login(){
      try{
        console.log("login")
        await this.$store.dispatch('auth/login', {
          email: this.email,
          password: this.password
        })        
      } catch(err) {
          console.log('Cannot login user. Error:', err)       
      }

    }
  }
}
</script>
